import { Model, useRepo } from 'pinia-orm';
import Meter from './Meter';
import SmartMeter from './SmartMeter';
import User from './User';
import Property from './Property';
import EnergyCommunityParticipation from './EnergyCommunityParticipation';

export default class EnergyCommunity extends Model {
    static entity = 'energyCommunities';

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(''),
            external_community_id: this.string(null),
            external_eda_partner_id: this.attr(null),
            participation_model: this.attr('static'),
            community_type: this.attr('csc'),
            static_share: this.number(null),
            participation_factor: this.number(null),
            static_share_calculated: this.number(null),
            first_billing_period: this.attr(null),
            billing_month: this.number(null),
            operator_company_name: this.string(null),
            operator_street: this.string(null),
            operator_postcode: this.string(null),
            operator_city: this.string(null),
            operator_contact_name: this.string(null),
            meta: this.attr(null),
            has_eda_client: this.boolean(false),

            // Relationships
            meters: this.hasMany(Meter, 'energy_community_id'),
            consumptionMeters: this.hasMany(Meter, 'energy_community_id'),
            productionMeters: this.hasMany(Meter, 'energy_community_id'),
            participations: this.hasMany(EnergyCommunityParticipation, 'energy_community_id'),
            energyCommunityManagers: this.hasMany(User, 'energy_community_id'),
            property: this.belongsTo(Property, 'property_id'),
        };
    }

    static repo() {
        return useRepo(EnergyCommunity);
    }
}
