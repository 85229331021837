/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import { Model, useRepo } from 'pinia-orm';
import Contract from './Contract';
import EnergyCommunity from './EnergyCommunity';
import SmartMeter from './SmartMeter';

export default class EnergyCommunityParticipation extends Model {
    static entity = 'energyCommunityParticipations';

    static fields() {
        return {
            id: this.attr(null),
            smart_meter_id: this.attr(null),
            energy_community_id: this.attr(null),
            consent_id: this.attr(null),
            participation_factor: this.attr(null),
            static_share: this.attr(null),
            static_share_calculated: this.attr(null),
            activation_date: this.attr(null),
            deactivation_date: this.attr(null),
            name: this.attr(null),
            address: this.attr(null),
            postcode: this.attr(null),
            city: this.attr(null),
            staircase: this.attr(null),
            door: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            energyCommunity: this.belongsTo(EnergyCommunity, 'energy_community_id'),
            smartMeter: this.belongsTo(SmartMeter, 'smart_meter_id'),
        };
    }

    /**
     * Check if this participation is currently active.
     */
    get isActive() {
        const now = new Date();
        const activationDate = this.activation_date ? new Date(this.activation_date) : null;
        const deactivationDate = this.deactivation_date ? new Date(this.deactivation_date) : null;

        return activationDate && activationDate <= now &&
               (!deactivationDate || deactivationDate >= now);
    }

    /**
     * Check if this participation is currently inactive.
     */
    get isInactive() {
        return !this.isActive;
    }

    static repo() {
        return useRepo(EnergyCommunityParticipation);
    }
}
