// Style imports
import '../sass/font.css';
import '../sass/app.scss';

// Core Vue and routing
import { createApp } from 'vue';
import { createRouter } from '@/router';
import App from '@/App.vue';

// State management
import { createPinia } from 'pinia';
import { createORM } from 'pinia-orm';

// UI Framework and services
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import IstaPreset from './theme';

// Icons and components
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './icons';
import Breadcrumb from './components/Breadcrumb.vue';

// Form validation
import './vee-validate';

// HTTP client
import Axios from './axios';

// Error tracking
import * as Sentry from '@sentry/vue';
import isUndefined from 'lodash-es/isUndefined';

// Guards/Middleware
import useDataAccessGuard from '@/middleware/useDataAccessGuard.js';
import { useAuthenticationGuard } from '@/middleware/useAuthenticationGuard.js';

// Initialize core services
const pinia = createPinia().use(createORM());
const router = createRouter();
const app = createApp(App);

// Register core plugins
app.use(pinia);
app.use(router);
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);

// Configure PrimeVue
app.use(PrimeVue, {
    theme: {
        preset: IstaPreset,
        options: {
            darkModeSelector: false,
        },
    },
    locale: {
        firstDayOfWeek: 1,
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: [
            'Jänner',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember',
        ],
        monthNamesShort: [
            'Jän',
            'Feb',
            'Mär',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dez',
        ],
        today: 'Heute',
        clear: 'Löschen',
        dateFormat: 'dd.mm.yy',
        weekHeader: 'KW',
    },
});

// Configure Sentry error tracking
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    release: `vdm@${app.config.globalProperties.$appVersion}`,
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Avoided redundant navigation to current location',
        'Redirected when going from ',
        'Navigation cancelled from',
        'Navigation aborted from',
    ],
});

// Register global components
app.component('Breadcrumb', Breadcrumb);
app.component('FontAwesomeIcon', FontAwesomeIcon);

// Configure global properties
app.config.globalProperties.$appVersion = '3.9.3';
app.config.globalProperties.$appVersionSuffix = import.meta.env.VITE_VERSION_SUFFIX;
app.config.globalProperties.$http = Axios;
app.config.globalProperties.append = (path, pathToAppend) =>
    path + (path.endsWith('/') ? '' : '/') + pathToAppend;

// Setup route guards
useAuthenticationGuard(router);
useDataAccessGuard(router);

// Mount the application
app.mount('#app');
