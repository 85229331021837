/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import { Model, useRepo } from 'pinia-orm';

export default class GridOperator extends Model {
    static entity = 'gridOperators';

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            supports_csv_import: this.boolean(false),
            link: this.attr(null),
        };
    }

    static repo() {
        return useRepo(GridOperator);
    }
}
