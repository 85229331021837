/*
 * ******************************************************
 *  * Copyright (C) 2018-2020 webcrab GmbH
 *  * This file is part of ista VDM 2.0.
 *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  * Proprietary and confidential
 *  * Written by <phillip@webcrab.at>
 *  ******************************************************
 */

import { Model, useRepo } from 'pinia-orm';
import SmartMeter from './SmartMeter';

export default class SmartMeterCustomerConsent extends Model {
    static entity = 'smartMeterCustomerConsents';

    static fields() {
        return {
            id: this.attr(null),
            smart_meter_id: this.attr(null),
            consent_state: this.attr(null),
            consent_state_title: this.attr(null),
            consent_state_description: this.attr(null),
            consent_id: this.attr(null),
            cm_request_id: this.attr(null),
            request_type: this.attr(null),
            date_from: this.attr(null),
            date_to: this.attr(null),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            smartMeter: this.belongsTo(SmartMeter, 'smart_meter_id'),
        };
    }

    /**
     * Check if this consent is active.
     * Matches the backend implementation which checks for 'Active' state.
     */
    get isActive() {
        return this.consent_state === 'active';
    }

    /**
     * Check if this consent is inactive.
     * Matches the backend implementation which checks for 'Rejected' or 'Ended' states.
     */
    get isInactive() {
        return ['rejected', 'ended'].includes(this.consent_state);
    }

    /**
     * Check if this consent is in a pending state.
     */
    get isPending() {
        return [
            'requested',
            'pending_grid_operator',
            'waiting_for_user_confirmation',
            'disconnect_pending_grid_operator'
        ].includes(this.consent_state);
    }

    /**
     * Get the metering interval for this consent.
     */
    get meteringInterval() {
        return this.smartMeter?.meter?.precision;
    }

    static repo() {
        return useRepo(SmartMeterCustomerConsent);
    }
}
