import { Model, useRepo } from 'pinia-orm';
import Meter from './Meter';
import GridOperator from './GridOperator';
import User from './User';
import SmartMeterCustomerConsent from './SmartMeterCustomerConsent';
import EnergyCommunityParticipation from './EnergyCommunityParticipation';

export default class SmartMeter extends Model {
    static entity = 'smartMeters';

    static fields() {
        return {
            id: this.attr(null),
            meter_id: this.attr(null),
            metering_point_number: this.string(null),
            device_type_smart: this.string(null),
            created_by: this.attr(null),
            grid_operator_id: this.attr(null),
            energy_community_participation_state: this.attr(null),
            energy_community_consent_id: this.attr(null),
            metering_data_customer_consent_process_state: this.attr(null),
            metering_data_customer_consent_process_state_description: this.attr(null),
            metering_data_customer_consent_process_state_title: this.attr(null),
            authorize: this.attr([]),
            created_at: this.attr(null),
            updated_at: this.attr(null),

            // Relationships
            meter: this.belongsTo(Meter, 'meter_id'),
            gridOperator: this.belongsTo(GridOperator, 'grid_operator_id'),
            creator: this.belongsTo(User, 'created_by'),
            customerConsents: this.hasMany(SmartMeterCustomerConsent, 'smart_meter_id'),
            latestCustomerConsent: this.hasOne(SmartMeterCustomerConsent, 'smart_meter_id'),
            energyCommunityParticipations: this.hasMany(EnergyCommunityParticipation, 'smart_meter_id'),
            activeEnergyCommunityParticipation: this.hasOne(EnergyCommunityParticipation, 'smart_meter_id'),
        };
    }

    /**
     * Check if the smart meter has an active metering data customer consent.
     */
    get hasActiveMeteringDataCustomerConsent() {
        return this.latestCustomerConsent && this.latestCustomerConsent.isActive;
    }

    /**
     * Check if the smart meter doesn't have an active metering data customer consent.
     */
    get doesntHaveActiveMeteringDataCustomerConsent() {
        return !this.latestCustomerConsent || this.latestCustomerConsent.isInactive;
    }

    /**
     * Check if the smart meter can request metering data.
     */
    get canRequestMeteringData() {
        return this.meter?.energyCarrier?.isEdaConnectable &&
               this.doesntHaveActiveMeteringDataCustomerConsent &&
               !this.energy_community_id;
    }

    /**
     * Check if the smart meter can be registered with an energy community.
     */
    canBeRegisteredWithEnergyCommunity(energyCommunity) {
        return this.metering_point_number &&
               !this.energy_community_id &&
               this.doesntHaveActiveMeteringDataCustomerConsent &&
               energyCommunity?.canRegisterMeter?.(this);
    }

    /**
     * Force register the smart meter with an energy community.
     */
    forceRegisterWithEnergyCommunity(energyCommunity) {
        this.energy_community_id = energyCommunity.id;
        return true;
    }

    /**
     * Register the smart meter with an energy community if possible.
     */
    registerWithEnergyCommunity(energyCommunity) {
        if (!this.canBeRegisteredWithEnergyCommunity(energyCommunity)) {
            return false;
        }

        return this.forceRegisterWithEnergyCommunity(energyCommunity);
    }

    /**
     * Deregister the smart meter from its energy community.
     */
    deregisterFromEnergyCommunity() {
        this.energy_community_id = null;
        return true;
    }

    /**
     * Request metering data customer consent.
     */
    requestMeteringDataCustomerConsent() {
        // This would typically make an API call
        // Implementation would depend on your API structure
        return true;
    }

    /**
     * Revoke customer consent from EDA.
     */
    revokeCustomerConsentFromEda() {
        // This would typically make an API call
        // Implementation would depend on your API structure
        return true;
    }

    /**
     * Check if the smart meter was created by a specific user.
     */
    wasCreatedByUser(user) {
        const userId = typeof user === 'object' ? user.id : user;
        return this.created_by === userId;
    }

    /**
     * Check if the smart meter was created by any user.
     */
    get wasCreatedByAUser() {
        return this.created_by !== null;
    }

    /**
     * Check if the smart meter was created by ISTA.
     */
    get wasCreatedByIsta() {
        return !this.wasCreatedByAUser;
    }

    /**
     * Get the metering interval for this smart meter.
     */
    get meteringInterval() {
        return this.meter?.precision;
    }

    /**
     * Get the energy direction for this smart meter.
     */
    get energyDirection() {
        return this.meter?.energy_direction;
    }

    static repo() {
        return useRepo(SmartMeter);
    }
}
