import { Model, useRepo, useStoreActions } from 'pinia-orm';
import Meter from './Meter';
import Axios from '@/axios';

export default class EnergyCarrier extends Model {
    static entity = 'energyCarriers';

    static fields() {
        return {
            id: this.attr(''),
            climatically_adjusted: this.boolean(false),
            meter: this.hasMany(Meter, 'energy_carrier_id'),
            name: this.string(''),
            order: this.number(0),
            unit: this.string(''),
            has_room_analysis: this.boolean(false),
            settings: this.attr({}),
        };
    }

    static repo() {
        return useRepo(this);
    }
}
