import { defineRule, configure } from 'vee-validate';
import { alpha_num, alpha_spaces, length, min, numeric, required } from '@vee-validate/rules';
import de from '@vee-validate/i18n/dist/locale/de.json';
import { localize, setLocale } from '@vee-validate/i18n';

configure({
    generateMessage: localize({
        de: {
            // messages: de.messages,
            // merge messages with some custom ones
            messages: {
                ...de.messages,
                length: '{field} muss genau 0:{length} Zeichen lang sein.',
            },
            names: {
                metering_point_number: 'Zählpunktnummer',
            },
            fields: {
                metering_point_number: {
                    alpha_num:
                        '{field} darf nur alphanumerische Zeichen enthalten. Geben Sie die Zählpunktnummer ohne Punkte ein.',
                },
            },
        },
    }),
    classes: {
        passed: 'has-success',
        failed: 'has-error',
    },
});
setLocale('de');
defineRule('required', required);
defineRule('alpha_num', alpha_num);
defineRule('numeric', numeric);
defineRule('length', length);
defineRule('alpha_spaces', alpha_spaces);
defineRule('min', min);
