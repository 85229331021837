<!--
  - /*******************************************************
  -  * Copyright (C) 2018-2020 webcrab GmbH
  -  * This file is part of ista VDM 2.0.
  -  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  * Proprietary and confidential
  -  * Written by <phillip@webcrab.at>
  -  *******************************************************/
  -->

<template>
    <div class="panel hidden-print">
        <div class="searchform row">
            <form
                id="searchForm"
                class="form-horizontal row col-lg-42"
                role="form"
                @submit.prevent="submitSearchForm()"
            >
                <div class="col-sm-22">
                    <div class="form-group">
                        <label
for="inputName" class="col-xs-16 col-sm-14 control-label"
                            >Name</label
                        >
                        <div class="col-xs-32 col-sm-34">
                            <input
                                id="inputName"
                                v-model="searchParams.name"
                                type="text"
                                class="form-control"
                                placeholder="Name"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            for="inputEnergyCommunityNumber"
                            class="col-xs-16 col-sm-14 control-label"
                            >EG-Nr.</label
                        >
                        <div class="col-xs-32 col-sm-34">
                            <input
                                id="inputEnergyCommunityNumber"
                                v-model="searchParams.external_community_id"
                                type="text"
                                class="form-control"
                                placeholder="EG-Nr."
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm-26">
                    <div class="form-group">
                        <label
                            for="inputExternalEdaPartnerId"
                            class="col-xs-16 col-sm-18 col-md-20 control-label"
                            >EG-Marktpartnernummer</label
                        >
                        <div class="col-xs-32 col-sm-30 col-md-28">
                            <input
                                id="inputExternalEdaPartnerId"
                                v-model="searchParams.external_eda_partner_id"
                                type="text"
                                class="form-control"
                                placeholder="EG-Marktpartnernummer"
                            />
                        </div>
                    </div>
                    <div class="form-group hidden-lg">
                        <div
                            class="col-xs-offset-16 col-xs-32 col-sm-offset-18 col-sm-30 col-md-offset-20"
                        >
                            <button type="submit" class="btn btn-default">
                                <font-awesome-icon icon="fa-solid fa-search" />
                                Suchen
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="col-lg-6 visible-lg">
                <button type="submit" class="btn btn-default btn-block" form="searchForm">
                    <font-awesome-icon icon="fa-solid fa-search" />
                    Suchen
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { useEnergyCommunitySearchStore } from '@/stores/energyCommunitySearchStore';

    export default {
        name: 'EnergyCommunitySearch',
        data() {
            return {
                searchParams: {
                    name: '',
                    external_community_id: '',
                    external_eda_partner_id: '',
                },
            };
        },
        created() {
            // Initialize form with any existing search params from store
            const searchStore = useEnergyCommunitySearchStore();
            this.searchParams = { ...searchStore.params };
        },
        methods: {
            async submitSearchForm() {
                const searchStore = useEnergyCommunitySearchStore();
                searchStore.setSearchParams(this.searchParams);
            },
            resetForm() {
                const searchStore = useEnergyCommunitySearchStore();
                searchStore.resetSearchParams();
                this.searchParams = { ...searchStore.params };
            },
        },
    };
</script>

<style scoped></style>
